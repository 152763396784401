import React from 'react'
import './smartphone.css'
import illustration from '../../assets/illustration.svg'
import smartphoneill from '../../assets/smartphone-ill.png'

function Smartphone() {
  return (
    <div>
        <div className='smartphone-container flex-row'>
            <div className='smartphone-img '>
                <img src={illustration} alt='offer-img' width="100%"/>
            </div>
            <div className='smartphone-content'>
                <h3>The Smartphone Boom</h3>
                <p>The Internet is revolutionizing the way we live our
                    daily lives. Smartphones have become an intrinsic
                    part of our lives. The mobile phone industry is
                    growing by leaps and bounds with many different
                    functionalities.</p>

                <p>Cryptocurrency provides secure and anonymous digital
                    transactions. Developers have created a new
                    generation of smartphones with special capabilities
                    to protect virtual assets.
                </p>

                <p>Crypto soft wallets and exchanges are now part of
                    everyday must-have tools to operate in the crypto
                    space. Celphish will launch wallets and Dapp to
                    provide Crypto access to every smartphone user.
                
                </p>
            </div>
        </div>
        <div className='smartphone-img'>
         <img src={smartphoneill} alt='smartphoneill-img' width="100%"/>
        </div>
        
    </div>
  )
}

export default Smartphone