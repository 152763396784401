import React from 'react'
import logo from '../../assets/Logo.svg'
import facebook from '../../assets/fb.svg'
import instagram from '../../assets/ig.svg'
import twitter from '../../assets/tw.svg'
import './footer.css'


function Footer() {
  return (
    <footer>
        <div className='footer-col-1'>
            <div className='footer-logo'>
                <img src={logo}  width='167px' alt='logo-img'/>    
            </div>
            <div className='footer-links'> 
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><a href="/offer">What we offer</a></li>
                    <li><a href="/about">About</a></li>
                    <li><a href="/thesmartphoneboom">The Smartphone Boom</a></li>
                    
                </ul>
            </div>
            <div className='footer-links'> 
                <ul>
                    <li><a href="/roadmap">Roadmap</a></li>
                    <li><a href="/tokenomics">Tokenomics</a></li>
                    <li><a href="/faq">FAQs</a></li>
                </ul>
            </div>
        </div>
        <div className='footer-col-2  flex-row'>
            <div>
                <p>Celphish 2022 All rights reserved</p>
            </div>
            <div>
                <p>Privacy Policy</p>
            </div>
            <div>
                <p>Terms & Conditions</p>
            </div>
            <div className='social-icons'>
                <a href='#'><img src={facebook} alt='facebook-icon'/></a>
                <a href='#'><img src={instagram} alt='instagram-icon'/></a>
                <a href='#'><img src={twitter} alt='twitter-icon'/></a>
            </div>
        </div>

    </footer>
  )
}

export default Footer