import React from 'react'
import { Roadmap } from '../components/Export'

function Roadmappage() {
  return (
    <div>
        <Roadmap/>
    </div>
  )
}

export default Roadmappage