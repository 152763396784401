import './presaleStages.css'

const presaleData = [
    {
        Stage: 'Stage 1',
        AmountofToken:'35,903,700',
        Allocation:18,
        Startdate:'18 July',
        Enddate:'15 August',
        Weeks:4,
    },
    {
        Stage: 'Stage 2',
        AmountofToken:'14,959,875',
        Allocation:7.5,
        Startdate:'15 August',
        Enddate:'5 September',
        Weeks:3,
    },
    
    {
        Stage: 'Stage 3',
        AmountofToken:'8,975,925',
        Allocation:4.5,
        Startdate:'5 September',
        Enddate:'26 September',
        Weeks:3,
    },
    
    
  ];


function PresaleStages() {
  return (
    <div className='presale-stage'>
        <div>
           <h2>Presale Stages</h2>

            <div className='stages-container'>
            {presaleData.map((item, index)=>(
                <div className='stage-1'>
                    <h3>{item.Stage}</h3>
                    <div>
                        <h6>Amount of Token</h6>
                        <p>{item.AmountofToken}</p>
                    </div>
                    <div>
                        <h6>Allocation</h6>
                        <p>{item.Allocation}</p>
                    </div>
                    <div>
                        <h6>Start date</h6>
                        <p>{item.Startdate}</p>
                    </div>
                    <div>
                        <h6>End date</h6>
                        <p>{item.Enddate}</p>
                    </div>
                    <div>
                        <h6>Weeks</h6>
                        <p>{item.Weeks}</p>
                    </div>
                </div>
            ))}
            </div>
            <div className='total-token '>
                <div className='stage-1'>
                    <h6>Total Amount of Token</h6>
                    <p>199,465,000</p>
                </div>
                <div className='stage-1'>
                   <h6>30% of supply</h6>
                    <p>59,839,500</p>
                 </div>
                <div className='stage-1'>
                    <h6>Ticker</h6>
                    <p>Celphish</p>
                </div>
                <div className='stage-1'>
                    <h6>Starting price</h6>
                    <p>0.027852</p>
                 </div>
                </div>
        </div>
    </div>
  )
}

export default PresaleStages;