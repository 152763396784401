import React from 'react'
import './about.css'

 const About = () => {
  return (
    <div>
        <div className='about-header'>

        </div>
        <div className=' about-content flex-row'>
            <div className='flex-2fr'> 
                <h2>About</h2>
            </div>
            <div  className='flex-5fr'>
             <p>Celphish Finance is a community-driven project that enables 
                    multi-chain liquidity sourcing and trading from various 
                    liquidity sources, such as DEXs that operate on
                    several blockchain networks. This will allow users to trade
                    and swap tokens at the lowest prices at the time. Our goal
                    is to make crypto holders and enthusiasts perform crypto
                    activities with their smartphones without any barriers.
             </p> 
              <button className='btn buy'> <a href="#">Join our community </a></button>
            </div>
        </div>

    </div>
  )
}

export default About;