import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import {Footer,  Navbar}from "./components/Export";
import Home from './pages/Home'
import About from './pages/About'
import OfferPage from './pages/OfferPage'
import Smartphonepage from './pages/Smartphonepage'
import Tokenomicspage from './pages/Tokenomicspage'
import FaqPage from "./pages/FaqPage";
import PresaleStagesPage from "./pages/PresaleStagesPage";
import Finance from './pages/FinancePage'
import ComingsoonPage from "./pages/ComingsoonPage";
import RoadmapPage from "./pages/Roadmappage";



function App() {
  return (

    <Router>
       <ScrollToTop>
         <Navbar/>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/offer" element={<OfferPage/>} />
            <Route path="/thesmartphoneboom" element={<Smartphonepage/>} />
            <Route path="/tokenomics" element={<Tokenomicspage/>} />
            <Route path="/faq" element={<FaqPage/>} />
            <Route path="/presalestages" element={<PresaleStagesPage/>} />
            <Route path="/finance" element={<Finance />} />
            <Route path="/comingsoon" element={<ComingsoonPage />} />
            <Route path="/roadmap" element={<RoadmapPage />} />




          </Routes>
          <Footer/>
       </ScrollToTop>
    </Router>
  );
}

export default App;

