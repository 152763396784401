import React from 'react'
import './header.css'


const Header = () => {
    return (
        <>
        
            <div className='header-banner'>
                <div className=' header-container'>
                    <div className='center'>
                        <h1>A cryptocurrency token without barriers </h1>
                        <div className='button-container'>
                            <button className='btn buy'>Enter Presale </button>
                            <button className='btn view'>Read Whitepaper</button>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className=' display-mobile'>
            <h1>A cryptocurrency token without barriers </h1>
            <div className='button-container'>
                <button className='btn buy'>Enter Presale </button>
                <button className='btn view'>Read Whitepaper</button>
            </div>
            </div>
        </>
    )
}

export default Header
