import React from 'react'
import { Faq, Timer } from '../components/Export'

function FaqPage() {
  return (
    <div>
        <Timer/>
        <Faq/>
    </div>
  )
}

export default FaqPage