const TextSliderData = [
    {
        name: 'Staking Rewards',
        text:"Staking requires users to lock their tokens inside the protocol, lowering the amount of circulating tokens on the market and, as a result, favourably increasing the token's price. Users can stake to participate in making critical decisions and suggestions on the Celphish Finance ecosystem while also earning rewards. The ideas might be about transaction fees, token release schedules, or more significant issues like moving the project to a different network. Users staking tokens will earn a portion of the fees Celphish Finance would collect from the liquidity pools in the ecosystem.",
    },
    {
        name: 'Liquidity Rewards',
        text:'This is the token allocated as an incentive for liquidity providers. The traders in our ecosystem swapping tokens for one another will be paying a small percentage of the fee. A part collected would go to the liquidity providers as a reward for providing liquidity to the common liquidity pools. Liquidity providers can use Celphish Finance to offer more liquidity to the pools already built in the protocol. Liquidity providers would be able to deploy their assets more efficiently, and customers would pay less in slippage fees.',
       
    },
    {
        name: 'Private Sale',
        text:'Strategic investors with a significant quantity of investible capital will have the opportunity to invest in the project at this early stage of the investment round.',
    },
    {
        name: 'Team and Advisors',
        text:"This is the allocation reserved for the project's developer team, which includes constructive contributors like founders, developers, marketers, and advisors.",
    },
    {
        name: 'Seed Sale',
        text:"The Seed Sale is the Celphish Finance project's initial Token Sale. The project is still in development during this period.",
    },
    {
        name: 'Reserves',
        text:"This will be utilized in the future to develop the project further when necessary.",
    },
   
  ];

  export default TextSliderData