import React, {useState} from 'react'
import logo from '../../assets/Logo.svg'
import { FaTimes} from 'react-icons/fa'
import {BiMenuAltRight} from 'react-icons/bi'
import './navbar.css'



const Navbar = () => {
    const [click, setClick] = useState(false)
    const handleClick = () => setClick(!click)
    return (
        <div className='header'>
            <div className='container'>
                <div>
                    <img src={logo}  alt='logo'  className='logo'/>    
                </div>
                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li>
                        <a href='/'>Home</a>
                    </li>
                    <li>
                        <a href='/offer'>What we offer</a>
                    </li>
                    <li>
                        <a href='/about'>About</a>  
                    </li>
                    <li>
                        <a href='/thesmartphoneboom'>The Smartphone Boom</a>
                    </li>
                    <li>
                        <a href='/roadmap'>Roadmap</a>
                    </li>
                    <li>
                        <a href='/tokenomics'>Tokenomics</a>
                    </li>
                    <li>
                        <a href='/faq'>FAQs</a>
                    </li>
                   
                </ul>
                <div className='hamburger' onClick={handleClick}>
                    {click ? (<FaTimes className='centremenu' size={20} style={{color: 'white'}}/>) : (<BiMenuAltRight size={36} style={{color: '#14467C'}} />)}
                </div>
            </div>
        </div>
    )
}

export default Navbar
