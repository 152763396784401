import React from 'react'
import { Comingsoon } from '../components/Export'

function ComingsoonPage() {
  return (
    <div>
        <Comingsoon/>
    </div>
  )
}

export default ComingsoonPage