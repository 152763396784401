import React from 'react'
import { Timer, Tokenomics } from '../components/Export'

function Tokenomicspage() {
  return (
    <div>
        <Timer/>
        <Tokenomics/>
    </div>
  )
}

export default Tokenomicspage