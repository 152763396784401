import React from 'react';
import TextSliderData from './TextsliderData'
import './textSlider.css';

const Textslider = ({scrollRef}) => {
 

  return (
    <div className='text-container' ref={scrollRef}>
        {TextSliderData.map((item, index) => (
        <div className='flex-5fr tokenomics-distibution' key={index}>
        <div className='line-container'> 
            <span className='line'></span>
            <h3>{item.name}</h3>
        </div>
        <p>{item.text}</p>
                            
        </div>  
       ))}        
    </div>
  );
};

export default Textslider;