import React from 'react'
import tokenDistribution from '../../assets/tokenomics-img.svg'
import tokenDistributionSmall from '../../assets/token.svg'
import arrowleft from '../../assets/arrowleft.svg'
import Textslider from './Textslider'

import './tokenomics.css'


function Tokenomics() {

    const scrollRef = React.useRef(null);

    const scroll = (direction) => {
      const { current } = scrollRef;
  
      if (direction === 'left') {
        current.scrollLeft -= 600;
      } else {
        current.scrollLeft += 600;
      }
    };


  return (
        <div className='tokenomics'>
            <div>
            <div className=' about-content flex-row'>
                <div className='flex-2fr'> 
                    <h2>Tokenomics </h2>
                </div>
                <Textslider scrollRef={scrollRef}/>
                <div className="left_arrows">
                <img src={arrowleft} className="left-arrow-icon" onClick={() => scroll('right')} />
            </div>
            </div>
           
            </div>
            <div className='token-distribution-wide'>
                <h2>Token Distribution</h2>
                <img src={tokenDistribution} alt='tokenDistribution' width='100%'/>
            </div>

            <div className='token-distribution-small'>
                {/* <h2>Token Distribution</h2> */}
                <img src={tokenDistributionSmall} alt='tokenDistribution' width='300px'/>
                <div className='token-distribution-container'>
                    <div className='token-line-container'> 
                        <span className='line'></span>
                        <div className='liquidity'>
                            <h3>Liquidity Rewards</h3>
                            <p>61,500,000</p>
                        </div>
                    </div>

                    <div className='token-line-container'> 
                        <span className='line'></span>
                        <div className='liquidity'>
                            <h3>Presale</h3>
                            <p>45,100,000</p>
                        </div>
                    </div>

                    <div className='token-line-container'> 
                        <span className='line'></span>
                        <div className='liquidity'>
                            <h3>Staking Rewards</h3>
                            <p>20,500,000</p>
                        </div>
                    </div>

                    <div className='token-line-container'> 
                        <span className='line'></span>
                        <div className='liquidity'>
                            <h3>Seed Sale</h3>
                            <p>20,500,000</p>
                        </div>
                    </div>

                    <div className='token-line-container'> 
                        <span className='line'></span>
                        <div className='liquidity'>
                            <h3>Private Sale</h3>
                            <p>20,500,000</p>
                        </div>
                    </div>

                    <div className='token-line-container'> 
                        <span className='line'></span>
                        <div className='liquidity'>
                            <h3>Public Sale</h3>
                            <p>20,500,000</p>
                        </div>
                    </div>

                    <div className='token-line-container'> 
                        <span className='line'></span>
                        <div className='liquidity'>
                            <h3>Reserves</h3>
                            <p>10,250,000</p>
                        </div>
                    </div>
                    <div className='token-line-container'> 
                        <span className='line'></span>
                        <div className='liquidity'>
                            <h3>Team and Advisors</h3>
                            <p>615,000</p>
                        </div>
                    </div>


                </div>
            </div>

    </div>
  )
}

export default Tokenomics