import React from 'react'
import { Offer, Timer } from '../components/Export'

function OfferPage() {
  return (
    <div>
       <Timer/>
       <Offer/>
    </div>
  )
}

export default OfferPage