import React from 'react'
import minus from '../../assets/minus.svg'
import './faq.css'

const faqData = [
    {
        question: 'What is Celphish Finance?',
        text:"Celphish Finance is a community-driven project that enables multi-chain liquidity sourcing and trading from various liquidity sources, such as DEXs that operate on several blockchain networks. ",
    },
    {
        question: 'What inspired the creation of Celphish Finance? ',
        text:'Smartphones revolutionize our lives. They can provide entertainment, education, companionship, and much more. For this reason, Celphish Finance was created to focus on building an NFT marketplace and social media, allowing chat messaging, the creation of memes and NFTs, and other educational purposes.  ',
       
    },
    {
        question: 'Does Celphish Finance have staking rewards?',
        text:'Yes, users can stake to participate in making critical decisions and suggestions on the Celphish Finance ecosystem while also earning rewards',
    },
    {
        question: 'Will Celphish Finance allow users to buy and sell NFTs?',
        text:"Yes, and we are developing a solution to help artists, corporations, governments, and non-profit organizations tokenize their assets (art) and encourage value creation around them.",
    },
 
  ];


function Faq() {
  return (
    <div className='faq'>
        <div className='faq-container'>
            <h1>FAQs</h1>
            <div className='faq-item-container'>
                {faqData.map((item, index)=>(
                    <div className='faq-item' key={index}>
                        <div className='faq-question'>
                            <h2>{item.question}</h2>
                            <img src={minus} alt='minus-icon'/>
                        </div>
                        <div className='faq-text'>
                            <p>{item.text}</p>
                        </div>
                    </div>

                ))}
            </div>
        </div>
    </div>
  )
}

export default Faq