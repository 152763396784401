import React from 'react'
import './roadmap.css'

function Roadmap() {
  return (
    <div className='roadmap'>
        <h1>Roadmap</h1>
        <section className='roadmap-container'>
                <h2>Q1, 2022</h2>
            <section className='roadmap-q1'>             
                <div>
                    <h3>Phase I:Website Launched</h3>
                    <p>Creating our website is the
                        first step toward bringing
                        this idea to life. The website
                        will have a simple interface
                        and will accurately represent
                        the project.
                    </p>
                </div>
                <div className='phase-2'>
                    <h3>Phase II:Launching of NFT Collection</h3>
                    <p>The community will have its
                        NFT collection. Furthermore,
                        collectibles will be available
                        for people to possess at this
                        time.
                    </p>
                </div>
                <div>
                    <h3>Phase III:
                        Launch of Social Media
                        Platform</h3>
                    <p>This stage will see the debut
                        of a social network where
                        users may engage and
                        establish an active community.
                    </p>
                </div>
                <div>
                    <h3>Phase IV: Celphish Swap</h3>
                    <p>Crypto owners sometimes
                        need to get fiat money before
                        buying the coin they want.
                        The Celphish swap is a more
                        convenient way to get tokens
                        since it allows you to do it
                        right away.
                     </p>
                     <p>Celphish Swap has been built
                        to provide the greatest liquidity
                        and the best pricing to perform
                        a rapid exchange of crypto. To
                        switch between any two
                        supported tokens, users will
                        have to pay a minor charge.
                     </p>
                </div>
            </section>

            <h2>Q2, 2022</h2>
            <section className="roadmap-q1">
                <div>
                    <h3>Phase I:Launch Dapp</h3>
                    <p>Users will be able to utilize
                        Dapp applications in the
                        ecosystem. A single or
                        centralized body will not
                        control the DApp.
                    </p>
                </div>
                <div>
                    <h3>Phase II: NFT Marketplace</h3>
                    <p>Creating our website is the
                        first step toward bringing
                        this idea to life. The website
                        will have a simple interface
                        and will accurately represent
                        the project.
                    </p>
                </div>
                <div>
                    <h3>Phase III:
                        Launch of Multi-Chain
                        Bridge</h3>
                    <p>This stage will see the debut
                        of a social network where
                        users may engage and
                        establish an active community.
                    </p>
                </div>
                
            </section>
        </section>
    </div>
  )
}

export default Roadmap