import React from 'react'
import { Header, Timer, } from '../components/Export'

function Home() {
  return (
    <div>
       <Timer/>
        <Header/>
    </div>
  )
}

export default Home