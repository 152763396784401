import React, { useState, useEffect } from "react";
import './comingsoon.css'




function Comingsoon() {

    const [timerDays, setTimerDays] = useState();
    const [timerHours, setTimerHours] = useState();
    const [timerMinutes, setTimerMinutes] = useState();
    const [timerSeconds, setTimerSeconds] = useState();
  
    let interval;
  
    const startTimer = () => {
      const countDownDate = new Date("June 28,2022").getTime();
  
      interval = setInterval(() => {
        const now = new Date().getTime();
  
        const distance = countDownDate - now;
  
        const days = Math.floor(distance / (24 * 60 * 60 * 1000));
        const hours = Math.floor(
          (distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
        const seconds = Math.floor((distance % (60 * 1000)) / 1000);
  
        if (distance < 0) {
          // Stop Timer
          clearInterval(interval.current);
        } else {
          // Update Timer
          setTimerDays(days);
          setTimerHours(hours);
          setTimerMinutes(minutes);
          setTimerSeconds(seconds);
        }
      });
    };
  
    useEffect(() => {
      startTimer();
    }, []);
  

  return (
    <div className='coming-soon'>
        <h2>WE ARE COMING SOON</h2>
        <section className="timer-container">
            <section className="timer-coming-soon">
                <div className="clock ">
                    <section>
                    <p>{timerDays}</p>
                    <small>Days</small>
                    </section>
                    <span>:</span>
                    <section>
                    <p>{timerHours}</p>
                    <small>Hours</small>
                    </section>{" "}
                    <span>:</span>
                    <section>
                    <p>{timerMinutes}</p>
                    <small>Minutes</small>
                    </section>{" "}
                    <span>:</span>
                    <section>
                    <p>{timerSeconds}</p>
                    <small>Seconds</small>
                    </section>
                </div>
            </section>
      </section>
      <section>
      <a href='#'><button className="btn presale-btn">JOIN CELPHISH TOKEN PRE-SALE</button></a>
      </section>
    </div>
  )
}

export default Comingsoon