import React from 'react'
import { PresaleStages } from '../components/Export'

function PresaleStagesPage() {
  return (
    <div>
        <PresaleStages/>
    </div>
  )
}

export default PresaleStagesPage