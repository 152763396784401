import React from 'react'
import './offer.css'
import offerImg from '../../assets/offer-img.png'
import offer1 from '../../assets/offer1.svg'
import offer2 from '../../assets/offer2.svg'
import offer3 from '../../assets/offer3.svg'
import offer4 from '../../assets/offer4.svg'
import offer5 from '../../assets/offer5.svg'
import offer6 from '../../assets/offer6.svg'
import offer7 from '../../assets/offer7.svg'
import offer8 from '../../assets/offer8.svg'



function Offer() {
  return (
      <div className='offerpage'>
        <div className='offer-container about-content flex-row'>
        <div className='flex-2fr'>
            <img src={offerImg} alt='offer-img'/>
        </div>
        <div className='offer-content flex-5fr'>
            <h3>What we offer</h3>
            <p>We plan to scale up our operations regularly,
                offering users the best without the need for
                sophisticated devices. All you need is your
                smartphone.
            </p>
        </div>
        </div>

        <div className='offer-item-container'>
            <div className='offer-item-1'>
                <img src={offer1} alt='offer1-img'/>
            </div>
            <div className='offer-item-1'>
                <img src={offer2} alt='offer1-img'/>
            </div>
            <div className='offer-item-1'>
                <img src={offer3} alt='offer1-img'/>
            </div>
            <div className='offer-item-1'>
                <img src={offer4} alt='offer1-img'/>
            </div>
            <div className='offer-item-1'>
                <img src={offer5} alt='offer1-img'/>
            </div>
            <div className='offer-item-1'>
                <img src={offer6} alt='offer1-img'/>
            </div>
            <div className='offer-item-1'>
                <img src={offer7} alt='offer1-img'/>
            </div>
            <div className='offer-item-1'>
                <img src={offer8} alt='offer1-img'/>
            </div>
            
        </div>
        

      </div>
  )
}

export default Offer;