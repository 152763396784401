import React from 'react'
import {About, Timer} from '../components/Export'

function Aboutpage() {
  return (
    <div>
         <Timer/>
        <About/>
    </div>
  )
}

export default Aboutpage