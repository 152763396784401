import React from 'react'
import { Finance, Timer } from '../components/Export'

function FinancePage() {
  return (
    <div>
        <Timer/>
        <Finance/>
    </div>
  )
}

export default FinancePage