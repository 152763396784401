import React from 'react'
import { Smartphone, Timer } from '../components/Export'

function Smartphonepage() {
  return (
    <div>
       <Timer/>
        <Smartphone/>
    </div>
  )
}

export default Smartphonepage