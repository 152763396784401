import React from 'react'
import './finance.css'
import finance1 from '../../assets/finance1.svg'
import finance2 from '../../assets/finance2.svg'
import finance3 from '../../assets/finance3.svg'

function Finance() {
  return (
    <div className='finance'>
        <div className='finance-container'>
            <div className='finance-item flex-row'>
                <img src={finance1} alt='illustration'/>
                <p>Celphish Finance was created to focus on building an
                    NFT marketplace and social media, allowing chat
                    messaging, the creation of memes and NFTs, and other
                    educational purposes.
                </p>
            </div>
            <div className='finance-item re-order flex-row'>
                <p>Social media networks do not presently allow for
                    creating, purchasing, or selling NFTs. Nevertheless, the
                    basic community nature of the artworks is in line with
                    the DNA of social media networks.
                </p>
                <img src={finance2} alt='illustration'/>
            </div>
            <div className='finance-item flex-row'>
                <img src={finance3} alt='illustration'/>
                <p>Celphish Finance will become the preferred platform for
                    buying and selling NFTs. 
                </p>
            </div>
        </div>
    </div>
  )
}

export default Finance